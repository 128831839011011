import { GoogleForm } from "react-google-forms-hooks";

export const form : GoogleForm = {
  fvv: 1,
  pageHistory: 0,
  fbzx: "2090332066931075140",
  action: "e/1FAIpQLScMxglpk60ItSKK3Z_NoEUXGr_0cz26VB_p4gZb5iFE_H-3vg",
  title: "II JORNADAS DE MEDICINA INTERNA DOS AÇORES",
  description: "",
  fields: [
    {
      label: "Nome Completo",
      description: "",
      type: "SHORT_ANSWER",
      id: "307299298",
      required: true
    },
    {
      label: "Número do Cartão de Cidadão",
      description: "",
      type: "SHORT_ANSWER",
      id: "213239777",
      required: true
    },
    {
      label: "Profissão",
      description: "",
      type: "RADIO",
      id: "2121272999",
      options: [
        { label: "Médico Especialista", custom: false },
        { label: "Médico Interno", custom: false },
        { label: "Enfermeiro", custom: false },
        { label: "Estudante", custom: false },
        { label: "Outra", custom: false }
      ],
      required: true
    },
    {
      label: "Local de trabalho",
      description: "",
      type: "SHORT_ANSWER",
      id: "1127040152",
      required: true
    },
    {
      label: "Telefone",
      description: "",
      type: "SHORT_ANSWER",
      id: "2132570662",
      required: true
    },
    {
      label: "Email",
      description: "",
      type: "SHORT_ANSWER",
      id: "132479428",
      required: true
    },
    {
      label: "Inscrição no jantar",
      description: "",
      type: "DROPDOWN",
      id: "1989792125",
      options: [{ label: "Sim" }, { label: "Não" }],
      required: true
    }
  ],
  fieldsOrder: {
    132479428: 5,
    213239777: 1,
    307299298: 0,
    1127040152: 3,
    1989792125: 6,
    2121272999: 2,
    2132570662: 4
  }
};
import "./index.css";
import { GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";
import { form } from "./assets/json/form";
import ShortAnswerInput from "./components/ShortAnswer";
import DropdownInput from "./components/Dropdown";
import RadioInput from "./components/RadioInput";
import { useState } from "react";

type FormResponseType = {
  show: boolean;
  formClass?: string;
  title?: string;
  description?: any;
};

const App = () => {
  const [formResposnse, setFormResponse] = useState<FormResponseType>({
    show: false,
  });

  const methods = useGoogleForm({ form });

  const onSubmit = async (data: any) => {
    console.log(data);
    const test = await methods.submitToGoogleForms(data);

    const formAux: FormResponseType = { show: true };
    formAux.title = "Enviado com sucesso";
    formAux.description = (
      <>
        {
          "O seu lugar está garantido, reserve na sua agenda o dia 19 de janeiro."
        }
        <br />
        <br />
        {
          "Obrigado por fazer parte das II Jornadas de Medicina Interna dos Açores."
        }
      </>
    );
    formAux.formClass = "success";

    /*
    if (test) {
      formAux.title = "Enviado com sucesso";
      formAux.description = "O seu lugar está garantido, reserve na sua agenda o dia 19 de janeiro.<br /><br />Obrigado por fazer parte das II Jornadas de Medicina Interna dos Açores.";
      formAux.formClass = "success"
    } else {
      formAux.title = "Algo falhou...";
      formAux.description = "Algo falhou ao submeter a sua inscrição, por favor tente novamente...";
      formAux.formClass = "fail";
    }
*/
    setFormResponse(formAux);
  };

  const onCloseFormResponse = () => {
    setFormResponse((prev) => ({
      ...prev,
      show: false,
    }));

    window.location.reload();
  };

  return (
    <>
      <div className="app">
        <svg id="elem-1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient
              id="fill11"
              x1="1020"
              x2="1020"
              y1="193"
              y2="-10"
              gradientTransform="translate(-758 9)"
              gradientUnits="userSpaceOnUse"
              spreadMethod="reflect"
            >
              <stop stopColor="#B0500F" offset="0" />
              <stop stopColor="#EE8137" offset=".48" />
              <stop stopColor="#F4B183" offset="1" />
            </linearGradient>
          </defs>
          <path d="m0.201-0.617 124 197 400 6.62v-203z" fill="url(#fill11)" />
        </svg>
        <svg id="elem-2" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <radialGradient
              id="fill16"
              cx="4.31e6"
              cy="3.26e6"
              r="5.4e6"
              fy="8.47e6"
              gradientTransform="matrix(.000105 0 0 .000105 .667 .671)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#B4C7E7" />
              <stop offset=".46" stopColor="#4D79C7" />
              <stop offset="1" stopColor="#264478" />
            </radialGradient>
          </defs>
          <path
            d="m431 685-430-246v-439l660-0.0613 244 388z"
            fill="url(#fill16)"
            fillRule="evenodd"
          />
        </svg>
        <svg id="elem-3" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-842 -489)" fillRule="evenodd">
            <path d="m1280 826-252-189 252-148z" fill="#2c4c83" />
            <path d="m842 531 145 78-145 78z" fill="#a9d18e" />
            <path d="m1044 537-39 73-39-73z" fill="#548235" />
          </g>
        </svg>
        <div className="inicio">
          <div className="flexRow" style={{ justifyContent: "space-between" }}>
            <h1>
              <span>
                II Jornadas<sub>de</sub>
              </span>
              <span className="upper">
                Medicina Interna<sub>dos</sub>
              </span>
              <span className="upper">Açores</span>
              <span className="year">2024</span>
              {/*
                  <a href="#inscricao" className="bt-in">
                    Inscreva-se agora
                  </a>
                */}
            </h1>
            <div className="date">
              <div>
                <div className="flexRow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="pulse"
                  >
                    <g data-name="calendar">
                      <path d="M18 4h-1V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1zm12 14H6a1 1 0 0 1-1-1v-6h14v6a1 1 0 0 1-1 1z" />
                      <circle cx="8" cy="16" r="1" />
                      <path d="M16 15h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z" />
                    </g>
                  </svg>
                  <h2>19 de Janeiro</h2>
                </div>
                <div className="flexRow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M13.67 22h-.06a1 1 0 0 1-.92-.8L11 13l-8.2-1.69a1 1 0 0 1-.12-1.93l16-5.33A1 1 0 0 1 20 5.32l-5.33 16a1 1 0 0 1-1 .68zm-6.8-11.9l5.19 1.06a1 1 0 0 1 .79.78l1.05 5.19 3.52-10.55z" />
                  </svg>
                  <h3>
                    <span style={{ whiteSpace: "nowrap" }}>
                      Auditório da Biblioteca Pública
                    </span>{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      e Arquivo Regional de Ponta Delgada
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="programa">
          <div>
            <div
              className="flexRow"
              style={{ justifyContent: "center", columnGap: "30px" }}
            >
              <div
                className="flexRow"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                <h1 style={{ color: "#ff9900" }}>Comissão Organizadora</h1>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <g data-name="bulb">
                    <path
                      fill="#ff9900"
                      d="M12 7a5 5 0 0 0-3 9v4a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-4a5 5 0 0 0-3-9zm1.5 7.59a1 1 0 0 0-.5.87V20h-2v-4.54a1 1 0 0 0-.5-.87A3 3 0 0 1 9 12a3 3 0 0 1 6 0 3 3 0 0 1-1.5 2.59z"
                    />
                    <path
                      fill="#ff9900"
                      d="M12 6a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1z"
                    />
                    <path
                      fill="#ff9900"
                      d="M21 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"
                    />
                    <path
                      fill="#ff9900"
                      d="M5 11H3a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"
                    />
                    <path
                      fill="#ff9900"
                      d="M7.66 6.42L6.22 5a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0-.06-1.41z"
                    />
                    <path
                      fill="#ff9900"
                      d="M19.19 5.05a1 1 0 0 0-1.41 0l-1.44 1.37a1 1 0 0 0 0 1.41 1 1 0 0 0 .72.31 1 1 0 0 0 .69-.28l1.44-1.39a1 1 0 0 0 0-1.42z"
                    />
                  </g>
                </svg>
              </div>
              <table className="tOrg">
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>Presidente</b>
                      </p>
                    </td>
                    <td>
                      <p>Clara Paiva</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <b>Comissão Organizadora</b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <span style={{ whiteSpace: "nowrap" }}>
                          Alfredo Chávez
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          César Lourenço
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Cláudia Rodrigues
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Filipa Bacalhau Lima
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Luís Filipe Dias
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Margarida Moura
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Mariana Oliveira
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Mariano Pacheco
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Marisa Rocha
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Miriam Cimbron
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Nuno Almeida
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Patrícia Aranha
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Raquel Senra
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <b>Comissão Científica</b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <span style={{ whiteSpace: "nowrap" }}>
                          Clara Paiva
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Cláudia Rodrigues
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Margarida Moura
                        </span>
                        ,{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Mariano Pacheco
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <b>Secretariado</b>
                      </p>
                    </td>
                    <td>
                      <p>Paula Duarte</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <h1 style={{ color: "#ff9900", textAlign: "center" }}>Programa</h1>
            <div className="p-list">
              <div className="col">
                {/* 08:00 */}
                <div className="col-row-title">
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <span className="clock"></span>
                    <h2>08:00</h2>
                  </div>
                  <p>
                    <i>Abertura do Secretariado</i>
                  </p>
                </div>
                {/* 08:30 */}
                <div className="h1">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>08:30</h2>
                    </div>
                    <p>Sessão de Abertura</p>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Presidente das Jornadas</b>
                          </p>
                        </td>
                        <td>
                          <p>Clara Paiva</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Secretária Regional Saúde</b>
                          </p>
                        </td>
                        <td>
                          <p> Mónica Seidi</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>
                              Presidente do Conselho de Administração do HDES
                            </b>
                          </p>
                        </td>
                        <td>
                          <p>Manuela Gomes de Menezes</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>
                              Presidente do Conselho de Administração da USISM
                            </b>
                          </p>
                        </td>
                        <td>
                          <p>Sandra Silva</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Diretora Clínica do HDES</b>
                          </p>
                        </td>
                        <td>
                          <p> Paula Macedo</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 09:00 */}
                <div className="h2">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>09:00</h2>
                    </div>
                    <h3>Mesa de Risco Cardiovascular em Medicina Interna</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Fátima Pinto
                            <br />
                            César Lourenço
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#2c4c83",
                              margin: "10px 0px",
                            }}
                          >
                            <b>Temas/Palestrantes</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>
                              Diabetes Mellitus - novas tecnologias no controlo
                              metabólico
                            </b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Ricardo Veloso
                            <br />
                            (HH)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Pé diabético</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Alfredo Chávez
                            <br />
                            (HDES)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Dislipidemia</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            David Ferreira
                            <br />
                            (HSEIT)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>
                              Checklist para a Pré-Alta de Internamento por
                              Insuficiência Cardíaca
                            </b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Carolina Róias
                            <br />
                            (HDES)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Hipertensão Arterial</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Lénio Couto
                            <br />
                            (HSEIT)
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 10:30 */}
                <div className="h3_1">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="coffee"></span>
                      <h2>10:30</h2>
                    </div>
                    <p>
                      <i>Pausa para café</i>
                    </p>
                  </div>
                </div>
                {/* 11:00 */}
                <div className="h3_2">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>11:00</h2>
                    </div>
                    <h3>Ser Interno de Medicina Interna nos Açores</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Clara Paiva
                            <br />
                            Paula Costa
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#2c4c83",
                              margin: "10px 0px",
                            }}
                          >
                            <b>Temas/Palestrantes</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Ser Interno no HSEIT</b>
                          </p>
                        </td>
                        <td>
                          <p>Maria Beatriz Santos</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Ser Interno no HH</b>
                          </p>
                        </td>
                        <td>
                          <p>Ricardo Veloso</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Ser Interno no HDES</b>
                          </p>
                        </td>
                        <td>
                          <p>Mariana Oliveira</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 12:00 */}
                <div>
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>12:00</h2>
                    </div>
                    <h3>Consultoria em Medicina Interna</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>Fátima Pinto</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#2c4c83",
                              margin: "10px 0px",
                            }}
                          >
                            <b>Temas/Palestrantes</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Plano de Apoio à Ortopedia</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Nuno Almeida
                            <br />
                            (HDES)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Consultoria às ilhas de São Jorge e Graciosa</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Leonor Monjardino
                            <br />
                            (HSEIT)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Polo da Madalena do Pico</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Joana Cardoso
                            <br />
                            (HH)
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col">
                {/* 13:00 */}
                <div className="col-row-title">
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <span className="dinner"></span>
                    <h2>13:00</h2>
                  </div>
                  <p>
                    <i>Almoço</i>
                  </p>
                </div>
                {/* 14:30 */}
                <div className="h1">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>14:30</h2>
                    </div>
                    <h3>Áreas de Diferenciação da Medicina Interna no HDES</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Margarida Moura
                            <br />
                            Leonor Monjardino
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#2c4c83",
                              margin: "10px 0px",
                            }}
                          >
                            <b>Temas/Palestrantes</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Doença Cerebrovascular</b>
                          </p>
                        </td>
                        <td>
                          <p>Raquel Senra</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Cuidados Paliativos</b>
                          </p>
                        </td>
                        <td>
                          <p>Carolina Vidal</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Geriatria</b>
                          </p>
                        </td>
                        <td>
                          <p>Beatriz Amaral</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Doenças Autoimunes</b>
                          </p>
                        </td>
                        <td>
                          <p>Miriam Cimbron</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 15:45 */}
                <div className="h2_1">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>15:45</h2>
                    </div>
                    <h3>Hospitalização Domiciliária</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>Marisa Rocha</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#2c4c83",
                              margin: "10px 0px",
                            }}
                          >
                            <b>Temas/Palestrantes</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>O projeto do HDES</b>
                          </p>
                        </td>
                        <td>
                          <p>Filipa Lima</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>O projeto do HSEIT</b>
                          </p>
                        </td>
                        <td>
                          <p>Daniela Brito</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>O projeto do HH</b>
                          </p>
                        </td>
                        <td>
                          <p>Fátima Pinto</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 16:45 */}
                <div className="h2_2">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>16:45</h2>
                    </div>
                    <h3>Apresentação dos Pósteres selecionados e Café</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Clara Paiva
                            <br />
                            Cláudia Rodrigues
                            <br />
                            Margarida Moura
                            <br />
                            Mariano Pacheco
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 18:00 */}
                <div className="h3">
                  <div className="col-row-title">
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <span className="clock"></span>
                      <h2>18:00</h2>
                    </div>
                    <h3>Medicina Interna e a Medicina Geral e Familiar</h3>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <b>Moderadores</b>
                          </p>
                        </td>
                        <td>
                          <p>
                            Sandra Silva
                            <br />
                            Paula Macedo
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#2c4c83",
                              margin: "10px 0px",
                            }}
                          >
                            <b>Temas/Palestrantes</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Medicina Geral e Familiar</b>
                          </p>
                        </td>
                        <td>
                          <p>José Carvalho Santos</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <b>Medicina Interna</b>
                          </p>
                        </td>
                        <td>
                          <p>Luís Filipe Dias</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 18:30 */}
                <div className="col-row-title">
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <span className="clock"></span>
                    <h2>18:30</h2>
                  </div>
                  <p>
                    <i>Encerramento</i>
                  </p>
                </div>
                {/* 19:30 */}
                <div className="col-row-title">
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <span className="dinner"></span>
                    <h2>19:30</h2>
                  </div>
                  <p>
                    <i>Jantar</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="inscricao">
          <div className="posters">
            <h1 style={{ color: "#ff9900", textAlign: "center" }}>
              Submissão de pósteres científicos
            </h1>
            <div
              className="flexRow"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <p>
                  Para saber mais sobre a submissão de <b>resumos</b> consulte
                  as{" "}
                  <b>
                    <a href="posters.pdf" target="_blank" rel="noreferrer">
                      regras
                    </a>
                  </b>
                  .<br />
                  As submissões já estão fechadas.
                </p>
              </div>
              <svg
                id="file"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M15 16H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z" />
                <path d="M9 14h3a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z" />
                <path d="M19.74 8.33l-5.44-6a1 1 0 0 0-.74-.33h-7A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V9a1 1 0 0 0-.26-.67zM14 5l2.74 3h-2a.79.79 0 0 1-.74-.85zm3.44 15H6.56a.53.53 0 0 1-.56-.5v-15a.53.53 0 0 1 .56-.5H12v3.15A2.79 2.79 0 0 0 14.71 10H18v9.5a.53.53 0 0 1-.56.5z" />
              </svg>
            </div>
          </div>
          <div className="posters">
            <h1
              style={{
                color: "#ff9900",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Garanta o seu lugar nas II Jornadas de Medicina Interna dos Açores
            </h1>
            <h3
              style={{
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              O limite de inscrições gratuitas foi alcançado. Para mais
              informações, contacte a comissão organizadora.
            </h3>
          </div>
          {/*
          <GoogleFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <h1
                style={{
                  color: "#ff9900",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Garanta o seu lugar nas II Jornadas de Medicina Interna dos
                Açores
              </h1>
              <h3
                style={{
                  color: "#ff9900",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                Inscrição Gratuita
              </h3>
               <div
                className="flexRow"
                style={{ justifyContent: "space-between" }}
              >
                <div className="inputs">
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <label className="label">Nome Completo</label>
                    <ShortAnswerInput id="307299298" />
                  </div>
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <label className="label">Número do Cartão de Cidadão</label>
                    <ShortAnswerInput id="213239777" />
                  </div>
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <label className="label">Profissão</label>
                    <div className="radioGroup" id="job">
                      <RadioInput id="2121272999" />
                    </div>
                  </div>
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <label className="label">Local de trabalho</label>
                    <ShortAnswerInput id="1127040152" />
                  </div>
                  <div
                    className="flexRow"
                    style={{ alignItems: "center", columnGap: "10px" }}
                  >
                    <label className="label">Email</label>
                    <ShortAnswerInput
                      id="132479428"
                      pType="[^@\s]+@[^@\s]+\.[^@\s]+"
                      pTitle="O email deve ser contituído por denominação de utilizador e dominio separados por '@'"
                    />
                  </div>
                  <div
                    className="flexRow"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <label className="label">Telefone</label>
                      <ShortAnswerInput
                        id="2132570662"
                        styleProp={{ width: "140px" }}
                        pType="[0-9]{9}"
                        pTitle="O telefone deve conter 9 digitos numéricos"
                      />
                    </div>
                    <div
                      className="flexRow"
                      style={{ alignItems: "center", columnGap: "10px" }}
                    >
                      <label className="label">Inscrição no jantar</label>
                      <div className="select">
                        <DropdownInput id="1989792125" />
                      </div>
                    </div>
                  </div>
                  <button type="submit">Submeter</button>
                </div>
              </div> 
            </form>
          </GoogleFormProvider>
          */}
        </div>
        <div className="sponsors">
          <h2>Com o apoio de</h2>
          <div className="sponsor-list">
            <div className="sponsor-item">
              <a
                href="https://portal.azores.gov.pt/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/gra.png" alt="Governo Regional dos Açores" />
                <span>
                  <i></i>
                  Governo Regional dos Açores
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.facebook.com/hdespd/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/hdes-removebg.png"
                  alt="HDES | Hospital Divino Espiríto Santo"
                />
                <span>
                  <i></i>
                  HDES | Hospital Divino Espiríto Santo
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://hospitalhorta.pai.pt/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/hh.png" alt="HH | Hospital da Horta" />
                <span>
                  <i></i>
                  HH | Hospital da Horta EPER
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a href="https://www.hseit.pt/" target="_blank" rel="noreferrer">
                <img
                  src="/hseit.png"
                  alt="HSEIT | Hospital de Santo Espírito da Ilha Terceira"
                />
                <span>
                  <i></i>
                  HSEIT | Hospital de Santo Espírito da Ilha Terceira, EPER
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a href="https://simposium.pt/" target="_blank" rel="noreferrer">
                <img src="/sp.png" alt="Simposium Advanced Healthcare" />
                <span>
                  <i></i>
                  Simposium Advanced Healthcare
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.astrazeneca.pt/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/astrazeneca.jfif" alt="AstraZeneca" />
                <span>
                  <i></i>
                  AstraZeneca
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.lilly.com/pt"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/lilly.png" alt="Lilly" />
                <span>
                  <i></i>
                  Lilly
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.novartis.com/pt-pt/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/novratis.png"
                  alt="Novratis | Reimagining Medicine"
                />
                <span>
                  <i></i>
                  Novartis | Reimagining Medicine
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://static.simposium.pt/stores/ccm"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/CC.png" alt="Clincal Checker | Simposium" />
                <span>
                  <i></i>
                  Clincal Checker | Simposium
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.bial.com/pt/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/bial.jpg" alt="Bial" />
                <span>
                  <i></i>
                  Bial
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.sanofi.com/pt/portugal"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/sanofi.png" alt="Sanofi" />
                <span>
                  <i></i>
                  Sanofi
                </span>
              </a>
            </div>
            <div className="sponsor-item">
              <a
                href="https://www.viforpharma.es/pt"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/vifor.png" alt="Vifor Pharma" />
                <span>
                  <i></i>
                  Vifor Pharma
                </span>
              </a>
            </div>
          </div>
        </div>
        <footer>
          <p>
            © 2024, <b>Simposium</b> | Advanced Healthcare
          </p>
        </footer>
      </div>
      {formResposnse.show && (
        <div id="formResponse" style={{ opacity: formResposnse.show ? 1 : 0 }}>
          <div className={formResposnse.formClass}>
            <h3>{formResposnse.title}</h3>
            <p children={formResposnse.description} />
            <input
              type="button"
              className="bt-in"
              value="Fechar"
              onClick={onCloseFormResponse}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default App;
